import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Prompts.scss';

const Prompts = (props) => {
  const { apiUrl, token, email } = props;

  const [processing, setProcessing] = useState(false);
  const [openPrompt, setOpenPrompt] = useState({});
  const [userPromptUsages, setUserPromptUsages] = useState({});
  const [promptsLoaded, setPromptsLoaded] = useState(false);
  const [prompts, setPrompts] = useState([]);

  // ugh could have combined these in the first call but makes the backend more complicated
  // just for this one call
  const getUserPrompt = (promptId) => {
    axios.post(
      `${apiUrl}/get-user-prompt`,
        {
          user: email,
          promptId,
          token
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setOpenPrompt(res.data[0]);
        } else {
          alert('Failed to load prompt');
        }
      })
      .catch((err) => {
        alert('Failed to load prompt');
        console.error(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  const savePrompt = () => {
    setProcessing(true);

    axios.post(
      `${apiUrl}/update-prompt`,
        {
          user: email,
          promptData: openPrompt,
          token,
          id: openPrompt.id
        }
      )
      .then((res) => {
        if (res.status === 201) {
          alert('Prompt saved!');
        } else {
          alert('Failed to save prompt');
        }
      })
      .catch((err) => {
        alert('Failed to save prompt');
        console.error(err);
      })
      .finally(() => {
        setProcessing(false);
        getUserPrompts();
      });
  }

  const newPrompt = () => {
    setOpenPrompt(openPrompt => ({
      id: 0,
      prompt_name: "new prompt name",
      prompt: "new prompt body",
      new: true
    }));
  }

  const getUserPrompts = () => {
    axios.post(
      `${apiUrl}/get-user-prompts`,
        {
          user: email,
          token
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.prompts) {
            if (res.data?.promptUsage) {
              setUserPromptUsages(res.data.promptUsage);
            }
            setPrompts(res.data.prompts);
          }
        } else {
          alert('Failed to get prompts');
        }
      })
      .catch((err) => {
        alert('Failed to get prompts');
        console.error(err);
      })
      .finally(() => {
        setPromptsLoaded(true);
      });
  }

  const deletePrompt = () => {
    if (window.confirm("Delete prompt?")) {
      setProcessing(true);

      axios.post(
        `${apiUrl}/delete-prompt`,
          {
            user: email,
            promptId: openPrompt.id,
            token
          }
        )
        .then((res) => {
          if (res.status === 200 && !res.data?.err) {
            alert('Prompt deleted!');
            setOpenPrompt({});
          } else {
            alert('Failed to delete prompt');
          }
        })
        .catch((err) => {
          alert('Failed to delete prompt');
          console.error(err);
        })
        .finally(() => {
          setProcessing(false);
          getUserPrompts();
        });
    }
  }

  const makeActive = (promptId, promptUsage) => {
    setProcessing(true);

    axios.post(
      `${apiUrl}/set-active-prompt`,
        {
          user: email,
          promptId,
          promptUsage,
          token
        }
      )
      .then((res) => {
        if (res.status === 200 && !res.data?.err) {
          alert('Prompt usage set!');
        } else {
          alert('Failed to set prompt usage');
        }
      })
      .catch((err) => {
        alert('Failed to set prompt usage');
        console.error(err);
      })
      .finally(() => {
        setProcessing(false);
        getUserPrompts();
      });
  }

  useEffect(() => {
    if (prompts.length) {
      getUserPrompt(prompts[0].id);
    }
  }, [prompts]);

  useEffect(() => {
    getUserPrompts();
  }, []);

  return (
    <div className="Page__prompts">
      { !promptsLoaded && <div className="Page__prompts-loading">
        <h2>Loading Prompts...</h2>
      </div> }
      { promptsLoaded && <div className="Page__prompts-list">
        <h2>Prompts</h2>
        {!prompts.length && <h2>No prompts</h2>}
        <div className="Page__prompts-list-container">
          {prompts.map((prompt, index) => {
            if (prompt?.new) return;

            return <div
              key={index}
              className={`Page__prompts-list-prompt ${prompt.id === openPrompt.id ? 'open' : ''}`}
              title="Edit prompt"
              onClick={() => getUserPrompt(prompt.id)}
            >
              { prompt.prompt_name }
            </div>
          })}
        </div>
        <button
          type="button"
          className="Page__prompts-list-add"
          onClick={() => {
            newPrompt();
          }}
        >Add Prompt</button>
      </div> }
      { promptsLoaded && prompts.length > 0 && <div className="Page__prompts-editor">
        <h2>Prompt Editor</h2>
        <input
          className="Page__prompts-editor-name"
          placeholder="Prompt name"
          value={openPrompt.prompt_name || ''}
          onChange={(e) => {
            setOpenPrompt(openPrompt => ({
              ...openPrompt,
              prompt_name: e.target.value
            }));
          }}
        />
        <textarea
          className="Page__prompts-editor-body"
          placeholder="Prompt text"
          value={openPrompt.prompt}
          onChange={(e) => {
            setOpenPrompt(openPrompt => ({
              ...openPrompt,
              prompt: e.target.value
            }));
          }}
        />
        <div className="Page__prompts-editor-buttons">
          <button type="button" className="Page__prompts-editor-buttons-delete" onClick={() => deletePrompt()}>Delete</button>
          <span className="Page__prompts-editor-buttons-prompt-usage">
            <input
              type="checkbox"
              onChange={(e) => {
                if (userPromptUsages && userPromptUsages.ids.includes(openPrompt.id) && openPrompt.id in userPromptUsages.usage && userPromptUsages.usage[openPrompt.id].includes('transcript')) {
                  alert("Please make another prompt active, prompt can't be empty");
                  return;
                }

                makeActive(openPrompt.id, "transcript");
              }}
              checked={userPromptUsages && userPromptUsages.ids.includes(openPrompt.id) && openPrompt.id in userPromptUsages.usage && userPromptUsages.usage[openPrompt.id].includes('transcript')}
            />
            <p>Use for transcript</p>
          </span>
          <span className="Page__prompts-editor-buttons-prompt-usage">
            <input
              type="checkbox"
              onChange={(e) => {
                if (userPromptUsages && userPromptUsages.ids.includes(openPrompt.id) && openPrompt.id in userPromptUsages.usage && userPromptUsages.usage[openPrompt.id].includes('re-eval')) {
                  alert("Please make another prompt active, prompt can't be empty");
                  return;
                }

                makeActive(openPrompt.id, "re-eval");
              }}
              checked={userPromptUsages && userPromptUsages.ids.includes(openPrompt.id) && openPrompt.id in userPromptUsages.usage && userPromptUsages.usage[openPrompt.id].includes('re-eval')}
            />
            <p>Use for re-eval</p>
          </span>
          <button type="button" className="Page__prompts-editor-buttons-save-changes" onClick={() => savePrompt()}>Save Changes</button>
        </div>
      </div> }
      { promptsLoaded && !prompts.length && <div className="Page__prompts-editor">
        <p>Add a prompt</p>  
      </div>}
    </div>
  );
}

export default Prompts;
