import { useEffect, useState, useRef } from 'react';

import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";

import './App.css';

import Login from './pages/login/Login';
import AppHome from './pages/app-home/AppHome';
import AppNewEncounter from './pages/app-new-encounter/AppNewEncounter';
import Prompts from './pages/prompts/Prompts';
import Table from './pages/patient-encounters/PatientEncounters';

const apiUrl = window.location.host.includes('localhost')
  ? 'http://localhost:5998'
  : process.env.REACT_APP_API_URL;;

function App() {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  const socketRef = useRef(null);
  const localToken = sessionStorage.getItem('token') || '';
  const useToken = token || localToken;
  const localEmail = sessionStorage.getItem('email');
  const useEmail = email || localEmail;

  // this will be replaced with httpOnly cookie method
  useEffect(() => {
    if (token) {
      sessionStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    if (email) {
      sessionStorage.setItem('email', email);
    }
  }, [email]);

  useEffect(() => {
    // check for token
    if (!useToken) {
      if (!window.location.href.includes('/login')) {
        window.location.href = "/login";
      }
    } else {
      if (window.location.pathname === "/"){
        window.location.href = "/app/home";
      }
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<div>Landing Page</div>}/>
          <Route path="/login" element={<Login token={useToken} apiUrl={apiUrl} setToken={setToken} setEmail={setEmail}/>}/>
          <Route path="/register" element={<div>Register Page</div>}/>
          <Route path="/app/home" element={<AppHome token={useToken}/>}/>
          <Route path="/app/previous-encounters" element={<div>App View Previous Encounters Page</div>}/>
          <Route path="/app/new-encounter" element={<AppNewEncounter token={useToken} email={useEmail}/>}/>
          <Route path="/app-home" element={<div>Landing Page</div>}/>
          <Route path="/app/prompts" element={<Prompts apiUrl={apiUrl} token={useToken} email={useEmail}/>}/>
          <Route path="/app/encounters" element={<Table token={useToken} email={useEmail} socketRef={socketRef}/>}/>
          <Route path="*" element={<div>404 Page</div>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
